exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-donations-jsx": () => import("./../../../src/pages/donations.jsx" /* webpackChunkName: "component---src-pages-donations-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-member-gallery-jsx": () => import("./../../../src/pages/member-gallery.jsx" /* webpackChunkName: "component---src-pages-member-gallery-jsx" */),
  "component---src-pages-membership-apply-jsx": () => import("./../../../src/pages/membership/apply.jsx" /* webpackChunkName: "component---src-pages-membership-apply-jsx" */),
  "component---src-pages-membership-jsx": () => import("./../../../src/pages/membership.jsx" /* webpackChunkName: "component---src-pages-membership-jsx" */),
  "component---src-pages-multipurpose-spaces-jsx": () => import("./../../../src/pages/multipurpose-spaces.jsx" /* webpackChunkName: "component---src-pages-multipurpose-spaces-jsx" */),
  "component---src-pages-sanity-event-fields-slug-jsx": () => import("./../../../src/pages/{sanityEvent.fields__slug}.jsx" /* webpackChunkName: "component---src-pages-sanity-event-fields-slug-jsx" */),
  "component---src-pages-sanity-training-event-fields-slug-jsx": () => import("./../../../src/pages/{sanityTrainingEvent.fields__slug}.jsx" /* webpackChunkName: "component---src-pages-sanity-training-event-fields-slug-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-training-jsx": () => import("./../../../src/pages/training.jsx" /* webpackChunkName: "component---src-pages-training-jsx" */),
  "component---src-templates-member-page-jsx": () => import("./../../../src/templates/MemberPage.jsx" /* webpackChunkName: "component---src-templates-member-page-jsx" */)
}

